import { Service } from '@skyscraper'
import type Redis from 'ioredis'
import { z } from 'zod'

import { type MyContainer } from '~/container'

export const APPLICATION_EVENTS = ['ticketUpdated', 'notification', 'accountChanged'] as const

export type ApplicationEvent = (typeof APPLICATION_EVENTS)[number]

const optionsSchmea = z.preprocess(
  (v) => {
    if (typeof v === 'string') {
      try {
        return JSON.parse(v)
      } catch {
        return v
      }
    }
  },
  z.object({
    customerId: z.string(),
    userId: z.string().optional()
  })
)

type Options = z.infer<typeof optionsSchmea>

// A redis client can only be used for _either_ subscribing or publishing, so we need to create a dedicated subscriber client
export let eventSubscriber: Redis | undefined

export type EventHandler = (key: ApplicationEvent, options: Options) => void

export class EventsService extends Service<MyContainer> {
  public emit = (key: ApplicationEvent, options: Options) => {
    this.cnt.redis.publish(key, JSON.stringify(options))
  }

  public addListener = (event: ApplicationEvent, handler: EventHandler) => {
    if (!eventSubscriber) {
      this.cnt.logger.verbose('Creating redis client for event subscriptions')
      eventSubscriber = this.cnt.redis.duplicate()
    }

    eventSubscriber.subscribe(event, () => {
      this.cnt.logger.verbose(`Subscribed redis client to ${event}`)
    })

    const callback = (channel: string, message: string) => {
      this.cnt.logger.verbose(`Redis client received message on channel: ${channel}`)

      if (channel !== event) {
        return
      }

      const parsedOptions = optionsSchmea.safeParse(message)

      if (!parsedOptions.success) {
        return
      }

      return handler(event, parsedOptions.data)
    }

    eventSubscriber?.on('message', callback)

    return () => {
      this.cnt.logger.verbose(`Redis client unsubscribed from: ${event}`)
      eventSubscriber?.off('message', callback)
    }
  }
}
