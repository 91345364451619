import { useEffect } from 'react'
import { z } from 'zod'
import { zx } from 'zodix'

import type { MyLoaderArgs } from '~/container'
import type { ApplicationEvent } from '~/services/events'
import { APPLICATION_EVENTS, type EventHandler } from '~/services/events'
import { eventStream } from '~/utils/remix-utils/event-stream'
import { useEventSource } from '~/utils/remix-utils/use-event-source'
import { $path } from '~/utils/route-helpers'

const searchParamsSchema = z.object({
  subscriptions: z.preprocess((v) => (Array.isArray(v) ? v : [v]), z.array(z.enum(APPLICATION_EVENTS)))
})

export type SearchParams = z.infer<typeof searchParamsSchema>

export const loader = async ({ request, context: { cnt } }: MyLoaderArgs) => {
  const { subscriptions } = zx.parseQuery(request, searchParamsSchema)

  const { customer, user } = await cnt.services.authN.requireUserAndCustomer()

  return eventStream(request.signal, (send) => {
    const handler: EventHandler = (event, options) => {
      if (customer.id === options.customerId && (!options.userId || user.id === options.userId)) {
        send({
          data: JSON.stringify({
            event,
            date: Date.now().toString()
          })
        })
      }
    }

    const heartbeatInterval = setInterval(() => {
      send({
        data: 'heartbeat'
      })
    }, 1000 * 5)

    const unsubscribers = subscriptions.map((subscription) => cnt.services.events.addListener(subscription, handler))

    return () => {
      clearInterval(heartbeatInterval)
      unsubscribers.forEach((unsubscriber) => {
        unsubscriber()
      })
    }
  })
}

export function useApplicationEventListener(subscriptions: ApplicationEvent[], callback: () => void) {
  const data = useEventSource(
    $path(`/events/subscribe`, {
      subscriptions
    })
  )

  useEffect(() => {
    if (!data || data === 'heartbeat') {
      return
    }
    callback()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
